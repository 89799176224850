import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { externalLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: "Кухонный экран повара",
			desktopTitle: "Приём! Кухня на связи",
			subTitle: (
				<p className={styles.description}>
					Заказы мгновенно попадают на&nbsp;кухню и&nbsp;распределяются между
					поварами. Для каждого блюда&nbsp;&mdash; своя техкарта со&nbsp;списком
					ингредиентов и&nbsp;рецептом. Как только заказ готов, официант получает
					уведомление.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/kds-presentation.png"
					alt="кухонный экран повара"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={96}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayKds,
					alt: "Google Play Market",
				},
				appstore: {
					href: externalLinks.appstoreKds,
					alt: "Appstore",
				},
			},
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Аспаздың асүй экраны",
			desktopTitle: "Қабылдау! Асүй әрдайым байланыста",
			subTitle: (
				<p className={styles.description}>
					Тапсырыстар асүйге лезде түседі және аспаздар арасында бөлінеді. Әр тағам үшін - ингредиенттер
					тізімі мен рецепті бар өз техкартасы бар. Тапсырыс дайын бола салысымен, даяшы хабарлама алады.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/kds-presentation.png"
					alt="кухонный экран повара"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={96}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayKds,
					alt: "Google Play Market",
				},
				appstore: {
					href: externalLinks.appstoreKds,
					alt: "Appstore",
				},
			},
		}
	}

	if (locale === 'ru-BY') {
		return {
			pageName: "Кухонный экран повара",
			desktopTitle: "Приём! Кухня на связи",
			subTitle: (
				<p className={styles.description}>
					Заказы мгновенно попадают на&nbsp;кухню и&nbsp;распределяются между
					поварами. Для каждого блюда&nbsp;&mdash; своя техкарта со&nbsp;списком
					ингредиентов и&nbsp;рецептом. Как только заказ готов, официант получает
					уведомление.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/kds-presentation.png"
					alt="кухонный экран повара"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={96}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayKds,
					alt: "Google Play Market",
				},
				appstore: {
					href: externalLinks.appstoreKds,
					alt: "Appstore",
				},
			},
		}
	}

	return {
		pageName: "Кухонный экран повара",
		desktopTitle: "Приём! Кухня на связи",
		subTitle: (
			<p className={styles.description}>
				Заказы мгновенно попадают на&nbsp;кухню и&nbsp;распределяются между
				поварами. Для каждого блюда&nbsp;&mdash; своя техкарта со&nbsp;списком
				ингредиентов и&nbsp;рецептом. Как только заказ готов, официант получает
				уведомление.
			</p>
		),
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/kds-presentation.png"
				alt="кухонный экран повара"
				objectFit="contain"
				objectPosition="center"
				placeholder="blurred"
				quality={96}
			/>
		),
		storeBadges: {
			googleplay: {
				href: externalLinks.googlePlayKds,
				alt: "Google Play Market",
			},
			appstore: {
				href: externalLinks.appstoreKds,
				alt: "Appstore",
			},
		},
	}
}
