import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import { HEADER_THEMES } from '../components/_V2/Header'
import { FOOTER_THEMES } from '../components/_V2/Footer'

import ProductPresentation from '../pagesSections/kds/ProductPresentation'
import Advantages from '../components/_V2/Advantages'

import { getKdsMeta } from '../pagesSections/kds/meta'

import { getAdvantages } from '../pages-data/_V2/kds/advantages'
import { getFeaturesData } from '../pages-data/_V2/kds/features'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/kds.module.scss'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import UsageFeatures from '../pagesSections/kds/UsageFeatures'
import SettingsFeatures from '../pagesSections/kds/SettingsFeatures'
import BusinessTypes from '../components/_V2/BusinessTypes'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageKDS() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getKdsMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      headerTheme={HEADER_THEMES.DARK}
      footerTheme={FOOTER_THEMES.WHITE}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        className={classNames(styles.kds__advantages, pageStyles.pageSection)}
        hideImages={false}
        items={getAdvantages(localizationContext.locale)}
      />

      <SimpleFeatures
        className={pageStyles.pageSection}
        data={getFeaturesData(localizationContext.locale)}
      />

      <UsageFeatures className={pageStyles.pageSection} />

      <SettingsFeatures className={pageStyles.pageSection} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  // <main className={pageStyles.pageWrapper}>
  //
  //   {/* Обратный звонок сервиса "Mango" */}
  //   <div
  //     className="mango-callback"
  //     data-settings='{"type":"", "id": "MTAwMDI1NjY=","autoDial" : "0", "lang" : "ru-ru", "host":"lk.mango-office.ru/", "errorMessage": "В данный момент наблюдаются технические проблемы и совершение звонка невозможно"}'
  //   >
  //     <button
  //       className="button-widget-open"
  //       id="mango-widget-btn"
  //       style={{ display: 'none' }}
  //     >
  //       ☎ ️
  //     </button>
  //   </div>
  // </main>
  )
}
