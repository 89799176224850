import { Locales } from "../../../localization/types";
import kdsScreen from "../ProductPresentation/assets/kds-presentation.png";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getKdsMeta = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			title: "Кухонный экран для повара Quick Resto Kitchen Display System | Казахстан",
			metaTags: [
				{
					name: "description",
					content:
						"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}kds/`,
				},
				{
					property: "og:title",
					content: "Кухонный экран для повара Quick Resto Kitchen Display System | Казахстан",
				},
				{
					property: "og:description",
					content:
						"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${kdsScreen}`,
				},
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1, shrink-to-fit=no",
				},
				{
					httpEquiv: "x-ua-compatible",
					content: "ie=edge",
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}kds/`,
		};
	}

	if (locale === "ru-BY") {
		return {
			title: "Кухонный экран для повара Quick Resto Kitchen Display System | Беларусь",
			metaTags: [
				{
					name: "description",
					content:
						"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}kds/`,
				},
				{
					property: "og:title",
					content: "Кухонный экран для повара Quick Resto Kitchen Display System | Беларусь",
				},
				{
					property: "og:description",
					content:
						"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${kdsScreen}`,
				},
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1, shrink-to-fit=no",
				},
				{
					httpEquiv: "x-ua-compatible",
					content: "ie=edge",
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}kds/`,
		};
	}

	return {
		title: "Кухонный экран для повара Quick Resto Kitchen Display System",
		metaTags: [
			{
				name: "description",
				content:
					"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}kds/`,
			},
			{
				property: "og:title",
				content: "Кухонный экран для повара Quick Resto Kitchen Display System",
			},
			{
				property: "og:description",
				content:
					"Экран повара Quick Resto Kitchen — приложение для передачи заказа от официанта повару на кухню → Двусторонняя связь поваров и работников зала → Таймер приготовления блюд",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: `${externalLinks.quickrestoRU.href}${kdsScreen}`,
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1, shrink-to-fit=no",
			},
			{
				httpEquiv: "x-ua-compatible",
				content: "ie=edge",
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}kds/`,
	};
};
